@import "common";

.page_content {
	display: flex;
	flex-direction: column; }

.image1_text1_grid {
	display: flex;
	@media (min-width: 140mm) {
		flex-direction: row;
		.img-wrapper {
			max-width: calc(100% - 100mm); }
		.text1 {
			width: 90mm; } }
	@media (max-width: 140mm) {
		flex-direction: column;
		.img-wrapper {
			max-width: 100%; } }

	.text1 {
		background-color: $colour2;
		padding: 5mm; } }

